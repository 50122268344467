// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  var referrer = document.referrer

  //if landing URL does not exist in local storage, set current pathname
  if (
    typeof window !== 'undefined' &&
    window.localStorage &&
    !window.localStorage.getItem('lNdUrL')
  ) {
    window.localStorage.setItem('lNdUrL', location.href)
    window.localStorage.setItem('lNdUrLDtIme', new Date())
  }

  window.localStorage.setItem('crNtPaTH', location.href)
  window.localStorage.setItem(
    'prevPaTHnAme',
    prevLocation ? prevLocation.href : null
  )

  // window.CallTrk.swap();
}
